import React, { createContext, useContext, useEffect, useState } from "react"
import { firestore } from "utils/firebase"

import { useAuth } from "./AuthContext"
// import {
//   getOrgInvitedMembers,
//   getOrgMembers,
//   getUserOrgs,
// } from "services/organizations-service"

const ForeverXContext = createContext()

export const ForeverXProvider = (props) => {

  const [companyData, setCompanyData] = useState(null)

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    firestore
      .collection("foreverx")
      .doc("companyData")
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          let data = snapshot.data()
          setCompanyData({
            ...data,
          })
        }
      })
  }

  return (
    <ForeverXContext.Provider
      value={{
        companyData
      }}
    >
      {props.children}
    </ForeverXContext.Provider>
  )
}

export const useForeverX = () => useContext(ForeverXContext)
