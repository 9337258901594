import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Links } from "constants/Links"
import { View } from "react-native-web"

import { useDisplay } from "context/DisplayContext"
import { DashboardSection } from "screens/Dashboard/DashboardSection/DashboardSection"
import { AdminSection } from "screens/Admin/AdminSection/AdminSection"
import { UsersListing } from "screens/Users/UsersListing/UsersListing"
import { UserDetail } from "screens/Users/ModifyUser/UserDetail"
import { ReportedUsers } from "screens/ReportedUsers/ReportedUsers"
import { Feedback } from "screens/Feedback/Feedback"
import { MessagingList } from "screens/Messaging/MessagingList"
import { HearAboutUs } from "screens/HearAboutUs/HearAboutUs"

export default function MainNavigator() {
  const { showSidebar, setShowSidebar } = useDisplay()

  return (
    <View
      style={{
        flex: 1,
        marginLeft: showSidebar ? 280 : 60,
        alignItems: "center",
        justifyContent: "center",
        // paddingHorizontal: -1,
        minWidth: 1024,
        // paddingTop: 12,
      }}
    >
      <Routes>
        <Route exact path={Links.Dashboard} element={<DashboardSection />} />
        {/** redirects */}
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.Dashboard} />}
        />

        <Route exact path={Links.Team} element={<AdminSection />} />
        <Route exact path={Links.Users} element={<UsersListing />} />
        <Route exact path={Links.UserDetail} element={<UserDetail />} />

        <Route exact path={Links.ReportedUsers} element={<ReportedUsers />} />
        <Route exact path={Links.Feedback} element={<Feedback />} />
        <Route exact path={Links.HearAboutUs} element={<HearAboutUs />} />
        <Route exact path={Links.Messaging} element={<MessagingList />} />
        {/* <Route exact path={Links.UserDetail} element={<UserDetail />} /> */}
      </Routes>
    </View>
  )
}
