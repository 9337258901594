import React from "react";
import { View, StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { Dimensions, ScrollView } from "react-native-web";
import { ModalHeader } from "./ModalHeader";

const { height } = Dimensions.get("window");

export function FullModal(props) {
  const { children, setShowModal, title, minHeight } = props;
  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <ModalHeader title={title} onClose={() => setShowModal(false)} />
        <ScrollView
          style={{
            flex: 1,
            minWidth: 500,

            minHeight,
            backgroundColor: COLORS.primary.white,
          }}
        >
          {children}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    zIndex: 999,
    // flex: 1,

    width: "100%",
    height: height - 100,
    backgroundColor: "rgba(255,255,255,0.7)",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    // flex: 1,
    maxHeight: height - 200,
    borderRadius: 12,
    backgroundColor: COLORS.primary.white,
    paddingVertical: 12,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
});
