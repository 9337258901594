import { Table as MuiTable, TableContainer } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Table = ({
  disablePresetTheme = false,
  wrapperClassName = '',
  tableClassName = '',
  children
}) => {
  return (
    <TableContainer
      className={`
                customTableWrapper
                ${disablePresetTheme ? 'noPresetTheme' : 'presetTheme'}
                ${wrapperClassName}
            `}
    >
      <MuiTable
        className={`
                    customTable 
                    ${tableClassName}
                `}
      >
        {children}
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  disablePresetTheme: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  tableClassName: PropTypes.string,
  children: PropTypes.any
};

export default Table;
