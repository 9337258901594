import React, { useState, createContext, useEffect, useContext } from "react";
import { useAuth } from "./AuthContext";
import { firestore, storage } from "utils/firebase"
import firebase from "firebase/compat/app"
import { useUsers } from "./UserContext";

export const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const { currentAuth } = useAuth();
  const { userData } = useUsers();

  const [filteredChats, setFilteredChats] = useState([]);
  const [adminChats, setAdminChats] = useState([]);
  const [selectedAdminChat, setSelectedAdminChat] = useState(null);
  const [adminMessages, setAdminMessages] = useState([]);
  const [filter, setFilter] = useState("active");

  const [startFetchGetAdminMessages, setStartFetchGetAdminMessages] = useState(false);

  useEffect(() => {
    if (!currentAuth || !startFetchGetAdminMessages) return;
    console.log('fetching admin chats')
    var adminSubscription = firestore
      .collection("adminChats")
      .orderBy("lastMessage.lastUpdated", "desc")
      .limit(100)
      .onSnapshot(
        (querySnapshot) => {
          let chats = [];
          querySnapshot.forEach((doc) => {
            const user = userData.find((x) => x.id === doc.data().userId);
            if (!user) return
            let chat = {
              ...doc.data(),
              id: doc.id,
              isAdmin: true,
              user,
            };
            // console.log('chat', chat)
            chats.push(chat);
          });

          setAdminChats(chats);

        

          
        },
        (error) => {
          console.log(
            "error listening for admin chat changes",
            error,
            currentAuth?.uid
          );
        }
      );
    return () => {
      adminSubscription();
    };
  }, [currentAuth, startFetchGetAdminMessages]);

  useEffect(() => {
    if (!selectedAdminChat) {
      setAdminMessages([]);
      return;
    }
    // console.log('selectedChat changed', selectedChat)
    var subscription = firestore
      .collection("adminChats")
      .doc(selectedAdminChat.id)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .onSnapshot(
        (querySnapshot) => {
          let fetchedTexts = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            let text = {
              _id: doc.id,
              text: data.text,
              createdAt: data.createdAt.toDate(),
              user: data.user,
            };
            fetchedTexts.push(text);
          });

          setAdminMessages(fetchedTexts);
        },
        (error) => {
          console.log(
            "error listening for admin message changes",
            error,
            currentAuth?.uid
          );
        }
      );
    return () => subscription();
  }, [selectedAdminChat]);

  useEffect(() => {
    if (!adminChats) return;
    if (filter==='active') {
      setFilteredChats(adminChats.filter((chat) => chat.active));
    } else {
      setFilteredChats(adminChats.filter((chat) => !chat.active));
    }
  }, [filter, adminChats])

  const sendText = (text) => {
    // console.log('send text from context', text)
    const id = selectedAdminChat.id
    const userId = selectedAdminChat.userId
    const collection = "adminChats"

    const message = {
      text,
      createdAt: new Date(),
      user: {
        _id: "admin",
        name: "Admin",
      },
      userId
    }
    firestore
      .collection(collection)
      .doc(id)
      .collection("messages")
      .add(message)
      .then(() => {
        firestore
          .collection(collection)
          .doc(id)
          .update({
            lastMessage: {
              text: text,
              lastUpdated: new Date(),
              userId: 'admin',
            },
            lastMessageSeenBy: [],
          });
      });
  };

  const createChat = (userId) => {

    //1 build main message doc where all traffic happens
    let parentMessage = {
      userId,
      lastMessage: null,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    //2 create parent message doc
    firestore
      .collection("adminChats")
      .add(parentMessage)
      .then((docRef) => {
        parentMessage.id = docRef.id;
        setSelectedAdminChat(parentMessage);
      });
  };

  const updateSeen = () => {
    firestore
      .collection("adminChats")
      .doc(selectedAdminChat.id)
      .update({
        lastMessageSeenBy: firebase.firestore.FieldValue.arrayUnion('admin'),
      });
  };

  const getUsersPromise = (chat) => {
    return new Promise(async (resolve, reject) => {
      let userDoc = await firestore.collection("users").doc(chat.userId).get();
  
      if (userDoc.exists) {
        let user = {
          id: userDoc.id,
          ...userDoc.data(),
        };
  
        chat.user = user;
        const profilePicRef = storage
          .ref()
          .child("defaultPictures/" + user.id + ".jpg");
        const url = await profilePicRef.getDownloadURL().catch((e) => {});
        if (!url) resolve(null);
        chat.user.defaultPic = url;
        resolve(chat);
      } else {
        resolve(null);
      }
    });
  };

  return (
    <MessagesContext.Provider
      value={{
        sendText,
        createChat,
        adminChats,
        setAdminChats,
        filteredChats, setFilteredChats,
        selectedAdminChat,
        setSelectedAdminChat,
        adminMessages,
        setAdminMessages,
        filter, setFilter,
        setStartFetchGetAdminMessages
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
