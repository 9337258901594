import { getCityStatePromise } from "utils/exports-helper";
import { firestore, auth, storage } from "utils/firebase";
import { unique } from "utils/helpers";
import Geocode from "react-geocode";

// const baseURL = 'http://localhost:8080'
const baseURL = "https://api.rayzeapp.com";

export const getAdmin = async (email, setAdminId) => {
  const querySnapshot = await firestore
    .collection("admins")
    .where("email", "==", email)
    .get();

  if (querySnapshot.docs.length > 0) {
    setAdminId(querySnapshot.docs[0].data().adminId);
  }
};

export const listenToUser = (adminId, setCurrentUser, onError) => {
  return firestore
    .collection("admins")
    .doc(adminId)
    .onSnapshot(
      (documentSnapshot) => {
        //check if user active here
        if (documentSnapshot.exists) {
          let user = {
            id: adminId,
            ...documentSnapshot.data(),
          };

          setCurrentUser(user);
        } else {
          onError();
        }
      },
      (err) => {
        console.log("listenToUser Error", adminId, err);
      }
    );
};

export const listenToSelectedUser = (user, setSelectedUser, onError) => {
  return firestore
    .collection("users")
    .doc(user.id)
    .onSnapshot(
      (documentSnapshot) => {
        //check if user active here
        if (documentSnapshot.exists) {
          let temp = {
            id: documentSnapshot.id,
            ...documentSnapshot.data(),
          };
          let picturePromises = [temp].map((item, index) =>
            getProfilePictures(item)
          );
          Promise.all(picturePromises).then((newFiles) => {
            setSelectedUser(newFiles[0]);
          });
        } else {
          onError();
        }
      },
      (err) => {
        console.log("listenToSelectedUser Error", user.id, err);
      }
    );
};

export const createUser = (data, profilePic, setOnboarding) => {
  const uid = auth().currentUser.uid;
  data.createdAt = new Date();
  data.lastUpdated = new Date();
  data.followers = 0;
  data.following = 0;
  firestore
    .collection("users")
    .doc(uid)
    .set(data)
    .then(() => {
      profilePic && uploadProfilePicture(profilePic, setOnboarding);
      // setOnboarding(false)
    })
    .catch((e) => {
      console.log("api createUser error", e);
    });
};

//add in profile pic promise
export const getUser = async (userId, setCurrentUser = null) => {
  firestore
    .collection("users")
    .doc(userId)
    .get()
    .then((documentSnapshot) => {
      //check if user active here
      if (documentSnapshot.exists) {
        let user = {
          id: userId,
          ...documentSnapshot.data(),
        };
        if (setCurrentUser) setCurrentUser(user);
      } else {
        console.log("authApi getUser doesnt exist", userId);
        if (setCurrentUser) setCurrentUser(null);
      }
    });
};

export const uploadProfilePicture = (image, setLoading) => {
  const uid = auth().currentUser.uid;
  let profilePic = storage.ref().child("profilePictures/" + uid + ".jpg");

  var metadata = {
    cacheControl: "max-age=31536000",
  };

  profilePic
    .putFile(image, metadata)
    .then((snapshot) => {
      setLoading(false);
    })
    .catch((error) => {
      console.log("utils uploadProfilePicture error ", error);
      // setError();
    });
};

/** USER SPECIFIC */

export const updateUser = async (user, userData, saveSuccessful) => {
  firestore
    .collection("users")
    .doc(user.id)
    .update(userData)
    .then(() => {
      saveSuccessful && saveSuccessful();
    })
    .catch((e) => {
      console.log("api updateUser error: ", e);
    });
};

export const getUsersPromise = (id) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        //check if user active here
        if (doc.exists) {
          let user = {
            id: doc.id,
            ...doc.data(),
          };
          resolve(user);
        } else {
          resolve(null);
        }
      });
  });
};

export const getUsersFromMembersPromise = (userId, obj) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        //check if user active here
        if (doc.exists) {
          const data = doc.data();
          const birthday = data.birthday.toDate();
          data.birthday = birthday;
          let user = {
            ...obj,
            ...data,
          };
          resolve(user);
        } else {
          resolve(null);
        }
      });
  });
};

export const getUserEmail = (uid, setEmail) => {
  const url = baseURL + "/getUserEmail/" + uid;

  const requestOptions = {
    method: "GET",
  };

  //Part is going TO a client so the client needs billed

  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      // console.log('result getUserEmail',data);
      setEmail(data?.email);
    })
    .catch((e) => {
      console.log("error getUserEmail", e);
    });
};

export const updateMemberInfo = (org, user, data, onSuccess) => {
  firestore
    .collection("organizations")
    .doc(org.id)
    .collection("members")
    .doc(user.id)
    .update(data)
    .then(() => {
      onSuccess();
    })
    .catch((e) => {
      console.log("error updating member", e);
    });
};

export const getProfilePicture = async (uid, setProfilePic) => {
  const profilePic = storage.ref().child("profilePictures/" + uid + ".jpg");
  profilePic
    .getDownloadURL()
    .then((url) => {
      setProfilePic(url);
    })
    .catch((err) => {
      setProfilePic(null);
    });
};

export const fetchMoreUsers = async (
  lastDoc,
  setLastDoc,
  users,
  setUsers,
  setLoading
) => {
  setLoading(true);
  let userRef = firestore
    .collection("users")
    .orderBy("createdAt", "desc")
    // .limit(10);

  console.log("lastDoc", lastDoc);
  // let tempUsers = [...users];
  let tempUsers = []

  // if (lastDoc) {
  //   userRef = firestore
  //     .collection("users")
  //     .orderBy("createdAt", "desc")
  //     .startAfter(lastDoc.createdAt)
  //     .limit(10);
  // }

  const usersSnap = await userRef.get().catch((e) => {
    console.log("fetchMoreUsers err", e);
  });

  usersSnap.docs.map(async (item, index) => {
    tempUsers.push({
      ...item.data(),
      id: item.id,
    });
  });

  setUsers(tempUsers);
  setLoading(false);

  return
  
  let picturePromises = tempUsers.map((item, index) =>
    getProfilePictures(item)
  );
  Promise.all(picturePromises).then((newFiles) => {
    setUsers(newFiles);
  setLoading(false);
  return;
    // let emailPromises = users.map((item, index) => getUserEmail(item))
    // Promise.all(emailPromises).then((files) => {
    Geocode.setApiKey("AIzaSyBKToZiSEjiW-U4gzpfqcoVf--l3t7j9ew");
    let promise = newFiles.map((x) => getCityStatePromise(x));
    Promise.all(promise).then((final) => {
      setUsers(final);
      setLastDoc(final[final?.length - 1]);
      setLoading(false);
    });
  });
  // setUsers(tempUsers)
};

export const getAllUsers = async (setUsers) => {
  let userRef = firestore.collection("users").orderBy("createdAt", "desc");
  let userQuery = await userRef.get().catch((e) => {
    console.log("getAllUsers err", e);
  });
  const temp = [];
  userQuery.forEach((doc) => {
    const user = {
      ...doc.data(),
      id: doc.id,
    };
    temp.push(user);
    if (user.state) return;
    Geocode.fromLatLng(user.lat, user.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        user.city = city;
        user.state = state;
        user.country = country;
        if (state) {
          firestore.collection("users").doc(user.id).update({
            state,
          });
        } else {
          firestore.collection("users").doc(user.id).delete();
        }

        //   console.log(address);
      },
      (error) => {
        console.error("location error", error, user.id);
      }
    );
  });
  setUsers(temp);
};

export const fetchNeedVerificationUsers = async (setUsers) => {
  let userRef = firestore
    .collection("users")
    .where("manualVer", "==", true)
    .where("verified", "==", false)
    .orderBy("createdAt", "desc");

  const usersSnap = await userRef.get().catch((e) => {
    console.log("fetchMoreUsers err", e);
  });

  let tempUsers = [];

  usersSnap.docs.map(async (item, index) => {
    tempUsers.push({
      ...item.data(),
      id: item.id,
    });
  });
  let picturePromises = tempUsers.map((item, index) =>
    getProfilePictures(item)
  );
  Promise.all(picturePromises).then((newFiles) => {
    // const sorted = newFiles.sort((a,b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1)
    //sort by manualVerificationRequestDate
    newFiles.sort((a, b) => {
      return ((b.manualVerificationRequestDate ? b.manualVerificationRequestDate.toDate() : b.createdAt.toDate()) > (a.manualVerificationRequestDate ? a.manualVerificationRequestDate.toDate() : a.createdAt.toDate()) ? 1 : -1);
    });
    setUsers(newFiles);
  });
  // setUsers(tempUsers)
};

function getProfilePictures(item) {
  return new Promise((resolve, reject) => {
    const profilePic = storage
      .ref()
      .child("defaultPictures/" + item.id + ".jpg");
    profilePic
      .getDownloadURL()
      .then((url) => {
        item.defaultPic = url;
        resolve(item);
      })
      .catch((err) => {
        resolve(item);
      });
  });
}

export const getAdditionalPictures = async (uid, setImages) => {
  const listRef = storage.ref(`users/${uid}`);

  listRef
    .listAll(listRef)
    .then((res) => {
      let images = [];
      res.items.forEach((itemRef) => {
        images.push(itemRef);
        // All the items under listRef.
      });

      let promises = images.map((item, index) => getDownloadURL(item));
      Promise.all(promises).then((files) => {
        let temp = ["", "", "", "", "", ""];

        files.forEach((img, index) => {
          temp[index] = img;
        });

        setImages(temp);
        // console.log("filles", files);
      });
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
};

const getDownloadURL = (image) => {
  return new Promise((resolve, reject) => {
    image
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        console.log("didnt get user pic");
        reject();
        // reject()
      });
  });
};

export const searchUsers = (searchText, setResults) => {
  // const search = searchText.toLowerCase().replace(/[^a-zA-Z ]/g, "")
  let search = searchText;

  let firstNameQuery = firestore
    .collection("users")
    .where("firstName", ">=", search)
    .where("firstName", "<=", search + "\uf8ff")
    .limit(20);

  let lastNameQuery = firestore
    .collection("users")
    .where("lastName", ">=", search)
    .where("lastName", "<=", search + "\uf8ff")
    .limit(20);

  let promises = [];
  if (search) {
    promises = [firstNameQuery.get(), lastNameQuery.get()];
  }

  Promise.all(promises)
    .then((snapshots) => {
      const temp = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const data = doc.data();
          const result = {
            id: doc.id,
            ...data,
          };
          temp.push(result);
        }
      }
      let uniq = unique(temp);
      let picturePromises = uniq.map((item, index) => getProfilePictures(item));
      Promise.all(picturePromises).then((newFiles) => {
        setResults(newFiles);
      });
    })
    .catch((e) => {
      console.log("error getting user search", e);
    });
};

export const deleteUser = (user, onSuccess) => {
  firestore
    .collection("users")
    .doc(user?.id)
    .delete()
    .then(() => {
      onSuccess();
    })
    .catch((e) => {
      console.log("error deleting user", e);
    });
};
