import { PrimaryText } from "components/Common/PrimaryText"
import React from "react"
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  TouchableOpacity,
} from "react-native"
// import { AlertCircle } from 'react-native-feather';
import { COLORS } from "constants/Colors"
import moment from "moment"
import { FaCalendar, FaChevronDown, FaChevronUp } from "react-icons/fa"
import { RiAlertFill } from "react-icons/ri"

export const CustomInput = (props) => {
  const {
    title,
    placeholder,
    onChange,
    errorMessage,
    value,
    text,
    secure,
    date,
    time,
    onPress,
    multiline,
    keyboardType,
    timeModalOpen,
    onBlur,
    darkMode,
    maxLength,
  } = props

  const style = styles(multiline)

  return (
    <View style={style.container}>
      <PrimaryText fontSize={14} fontWeight={700} style={style.inputTitle}>
        {title}
      </PrimaryText>
      {!date && (
        <TextInput
          {...props}
          maxFontSizeMultiplier={1}
          style={style.input}
          placeholder={placeholder}
          keyboardType={keyboardType}
          placeholderTextColor={"lightgrey"}
          value={value}
          onChangeText={(e) => onChange(e)}
          secureTextEntry={secure}
          textContentType={"oneTimeCode"}
          multiline={multiline}
          maxLength={maxLength || 500}
          onBlur={onBlur}
          autoCapitalize={"none"}
        />
      )}
      {date && (
        <TouchableOpacity
          onPress={onPress}
          disabled={!onPress}
          style={style.input}
        >
          {time ? (
            <PrimaryText fontSize={16} fontWeight={400} style={style.value}>
              {moment(value).format("LT")}
            </PrimaryText>
          ) : (
            <PrimaryText fontSize={16} fontWeight={400} style={style.value}>
              {text || value.toLocaleDateString()}
            </PrimaryText>
          )}
          <View>
            {time ? (
              !timeModalOpen ? (
                <FaChevronDown style={style.icon} />
              ) : (
                <FaChevronUp style={style.icon} />
              )
            ) : (
              <FaCalendar style={style.icon} />
            )}
          </View>
        </TouchableOpacity>
      )}
      {errorMessage && (
        <View style={style.errorContainer}>
          <RiAlertFill
            width={16}
            height={16}
            fill={COLORS.primary.error}
            stroke={COLORS.primary.white}
          />
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={style.errorMessage}
          >
            {errorMessage}
          </PrimaryText>
        </View>
      )}
    </View>
  )
}

const styles = (multiline) =>
  StyleSheet.create({
    container: {
      flexDirection: "column",
      marginBottom: 12,
    },
    inputTitle: {
      paddingLeft: 10,
      marginBottom: 5,
      color: COLORS.primary.darkGray,
    },
    input: {
      height: multiline ? 128 : 40,
      paddingHorizontal: 16,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      fontSize: 16,
      color: COLORS.primary.darkGray,
      backgroundColor: "white",
      borderColor: "#999999",
      borderWidth: 0.5,
      borderRadius: 15,
    },
    value: {
      color: COLORS.primary.darkGray,
    },
    icon: {
      tintColor: COLORS.primary.darkGray,
      height: 24,
      width: 24,
    },
    errorContainer: {
      backgroundColor: COLORS.background.lightRed,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 8,
      borderRadius: 8,
      flexDirection: "row",
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
  })
