import React, { createContext, useContext, useEffect, useState } from "react";
import {
  fetchMoreUsers,
  fetchNeedVerificationUsers,
  getAdditionalPictures,
  listenToSelectedUser,
} from "services/user-service";
import { useAuth } from "./AuthContext";

export const UsersContext = createContext();

const options = [
  { value: "all", label: "All" },
  { value: "verification", label: "Need Verification" },
];

export const UsersProvider = ({ children }) => {
  const { currentAuth } = useAuth();

  const [lastDoc, setLastDoc] = useState(null);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(true);

  //verification
  const [usersWhoNeedVerification, setUsersWhoNeedVerification] = useState([]);
  const [selectedView, setSelectedView] = useState(options[0]?.value);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!currentAuth) return;

    // getUsers()
    fetchMoreUsers(lastDoc, setLastDoc, userData, setUserData, setLoading);
    fetchNeedVerificationUsers(setUsersWhoNeedVerification);
  }, [currentAuth]);

  useEffect(() => {
    if (!selectedUser) return;

    getAdditionalPictures(selectedUser.id, setImages);
    return () => {
      setImages(null);
    };
  }, [selectedUser]);

  function nextPage(page, setPage, type) {
    const newPage = page + 1;
    if (userData?.length / (newPage * 10) < 1) {
      fetchMoreUsers(lastDoc, setLastDoc, userData, setUserData, setLoading);
    }

    setPage(newPage);
  }

  return (
    <UsersContext.Provider
      value={{
        userData,
        setUserData,
        nextPage,
        selectedUser,
        setSelectedUser,
        loading,
        setLoading,
        images,
        usersWhoNeedVerification,
        setUsersWhoNeedVerification,
        selectedView,
        setSelectedView,
        search,
        setSearch,
        page,
        setPage,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
