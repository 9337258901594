import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import { View } from "react-native-web"
import { COLORS } from "constants/Colors"

const DashboardItem = ({
  children,
  className = "",
  contentWrapperClassName = "",
  ...otherProps
}) => {
  return (
    <View
      style={{
        flex: 1,
        borderColor: COLORS.primary.gray,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 24,
        justifyContent: "space-between",
        // alignItems: "center",
      }}
    >
      {children}
    </View>
  )
}

DashboardItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentWrapperClassName: PropTypes.string,
}

export default DashboardItem
