import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GiIcons from "react-icons/gi";
import { Links } from "constants/Links";

export const SidebarData = [
  {
    title: "Dashboard",
    path: Links.Dashboard,
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: "Administrators",
    path: Links.Team,
    icon: <FaIcons.FaUsersCog />,
  },
  {
    title: "Users",
    path: Links.Users,
    icon: <FaIcons.FaUser />,
    detailedNav: [
      {
        title: "User Detail",
        path: Links.UserDetail,
        icon: <IoIcons.IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Reported Users",
    path: Links.ReportedUsers,
    icon: <IoIcons.IoIosPaper />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Feedback",
    path: Links.Feedback,
    icon: <IoIcons.IoMdInformationCircle />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Heard About Us",
    path: Links.HearAboutUs,
    icon: <IoIcons.IoMdInformationCircle />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Admin Messaging",
    path: Links.Messaging,
    icon: <RiIcons.RiMessage2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: 'Support',
  //   path: Links.Support,
  //   icon: <IoIcons.IoMdHelpCircle />
  // }
];
