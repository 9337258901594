import { firestore, storage } from "utils/firebase";

export const getHeardAboutUs = async (setFeedback, userData) => {
  const querySnapshot = await firestore
  .collection("hearAboutUs")
  .orderBy('createdAt', 'desc')
  .get()
  .catch((e) => {
    console.log('error getting heard about us docs', e);
  });

  const reported = [];
  querySnapshot.forEach((doc, index) => {
    const report = doc.data();
    
    const user = userData.find((x) => report.userId === x.id);
    
    if (!user) return
    reported.push({ ...doc.data(), id: doc.id, user });
  });

  const reports = reported.filter((x) => x !== null);
  setFeedback(reports);
};