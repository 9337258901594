import React, { useState, createContext, useEffect, useContext } from "react"
import { auth } from "utils/firebase"
import { logout } from "../services/auth-service"
import { getAdmin, listenToUser } from "../services/user-service"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [initializing, setInitializing] = useState(true)
  const [currentAuth, setCurrentAuth] = useState(null)
  const [adminId, setAdminId] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Handle user state changes
  function onAuthStateChanged(auth) {
    auth?.email && getAdmin(auth?.email, setAdminId)
    setCurrentAuth(auth)
    if (initializing) setInitializing(false)
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged)
    return subscriber // unsubscribe on unmount
  }, [])

  useEffect(() => {
    if (!adminId) return

    const userSubscriber = listenToUser(adminId, setCurrentUser, onError)

    return () => {
      setCurrentUser(null)
      userSubscriber()
    }
  }, [adminId])

  function onError() {
    window.alert("no user found")
    logout()
  }

  if (initializing) return null

  return (
    <AuthContext.Provider
      value={{
        initializing,
        currentAuth,
        setCurrentAuth,
        currentUser,
        setCurrentUser,
        adminId,
        setAdminId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
