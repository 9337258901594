import React, { createContext, useContext, useEffect, useState } from "react";
import { getHeardAboutUs } from "services/heardAboutUs-service";
import { useUsers } from "./UserContext";

export const HeardAboutUsContext = createContext();

export const HeardAboutUsProvider = ({ children }) => {
  const { userData } = useUsers();
  const [feedback, setFeedback] = useState([]);
  const [feedbackUsers, setFeedbackUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  const [feedBackByType, setFeedBackByType] = useState([]);

  const [startFetchHeardAboutUs, setStartFetchHeardAboutUs] = useState(false);

  useEffect(() => {
    if (!startFetchHeardAboutUs) return;
    getHeardAboutUs(setFeedbackUsers, userData);
  }, [startFetchHeardAboutUs, userData]);

  useEffect(() => {
    if (!feedbackUsers.length) return;
    // count feedback by type
    const newArr = []
    const feedbackByType = feedbackUsers.reduce((acc, curr) => {
      const type = curr.hearAboutUs;
      if (!newArr.find(x => x.hearAboutUs === type)) {
        newArr.push({ hearAboutUs: type, count: 1 });
      } else {
        const findIndex = newArr.findIndex(x => x.hearAboutUs === type);
        newArr[findIndex].count++;
      }
      return newArr.sort((a, b) => (a.count < b.count ? 1 : -1));
    })
    setFeedBackByType(feedbackByType);

  }, [feedbackUsers]);

  return (
    <HeardAboutUsContext.Provider
      value={{
        filter,
        setFilter,
        feedbackUsers,
        setFeedbackUsers,
        feedBackByType,
        setStartFetchHeardAboutUs
      }}
    >
      {children}
    </HeardAboutUsContext.Provider>
  );
};

export const useHeardAboutUs = () => useContext(HeardAboutUsContext);
