import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import DashboardItem from "../DashboardItem/DashboardItem"
import { firestore } from "utils/firebase"
import { PrimaryText } from "components/Common/PrimaryText"
import { View } from "react-native-web"
import { useForeverX } from "context/ForeverXContext"

export const DashboardSection = () => {
  const { companyData } = useForeverX()

  return (
    <View style={{ flex: 1, width: '100%' }}>
      <Grid container className="dashboardSection">
        {/* -----------------------  Dashboard first row ends  ----------------------- */}
        <Grid item container xs={12} className="dashboardRow">
          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Total Users
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.usersCount}
            </PrimaryText>
          </DashboardItem>

          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Active Users
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.activeUserCount}
            </PrimaryText>
          </DashboardItem>
        </Grid>
        {/* -----------------------  Dashboard first row ends  ----------------------- */}

        {/* -----------------------  Dashboard second row starts ----------------------- */}
        <Grid item container xs={12} className="dashboardRow">
          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Messages Sent
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.messageCount}
            </PrimaryText>
          </DashboardItem>

          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Reports submitted
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.reportsCount}
            </PrimaryText>
          </DashboardItem>

          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Swipes
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.swipeCount}
            </PrimaryText>
          </DashboardItem>
        </Grid>

        {/* -----------------------  Dashboard second row ends ----------------------- */}

        {/* -----------------------  Dashboard third row  starts ----------------------- */}
        <Grid item container xs={12} className="dashboardRow lastRow">
          <DashboardItem>
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Matches Made
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.matchesCount}
            </PrimaryText>
          </DashboardItem>

          <DashboardItem
            item
            xs={12}
            sm={6}
            className="itemPadding registeredUsersItem"
          >
            <PrimaryText
              fontSize={20}
              fontWeight={700}
              style={{ marginVertical: 12 }}
            >
              Friendships Made
            </PrimaryText>
            <PrimaryText fontSize={36} fontWeight={800}>
              {companyData?.friendshipsCount}
            </PrimaryText>
          </DashboardItem>
        </Grid>
        {/* -----------------------  Dashboard third row  ends ----------------------- */}
      </Grid>
    </View>
  )
}
