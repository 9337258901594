export const monthOptions = [
  {
    name: "01 - January",
    id: 0,
  },
  {
    name: "02 - February",
    id: 1,
  },
  {
    name: "03 - March",
    id: 2,
  },
  {
    name: "04 - April",
    id: 3,
  },
  {
    name: "05 - May",
    id: 4,
  },
  {
    name: "06 - June",
    id: 5,
  },
  {
    name: "07 - July",
    id: 6,
  },
  {
    name: "08 - August",
    id: 7,
  },
  {
    name: "09 - September",
    id: 8,
  },
  {
    name: "10 - October",
    id: 9,
  },
  {
    name: "11 - November",
    id: 10,
  },
  {
    name: "12 - December",
    id: 11,
  },
]

export const yearOptions = () => {
  let date = new Date()
  let options = []

  for (let i = 0; i < 100; i++) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const newDate = new Date(year - i, month, day)
    let option = {
      id: newDate.getTime(),
      name: newDate.getFullYear(),
    }
    options.push(option)
  }

  return options
}

export const genders = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "gender fluid/non-binary",
    name: "Gender Fluid / Non-Binary",
  },
]

export const genderPreferences = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "gender fluid/non-binary",
    name: "Gender Fluid / Non-Binary",
  },
  {
    id: "all",
    name: "Open to All",
  },
]

export const covid19Options = [
  {
    id: "yes",
    name: "Yes",
  },
  {
    id: "no",
    name: "No",
  },
  {
    id: "n/a",
    name: "Prefer not to say",
  },
]

export const professions = [
  {
    id: 0,
    name: "Advanced Practice Registered Nurse",
  },
  {
    id: 1,
    name: "Audiologist",
  },
  {
    id: 2,
    name: "Chiropractor",
  },
  {
    id: 3,
    name: "Dental Hygienist",
  },
  {
    id: 4,
    name: "Dentist",
  },
  {
    id: 5,
    name: "Dietitian",
  },
  {
    id: 6,
    name: "Genetic Counselor",
  },
  {
    id: 7,
    name: "Imaging Technician",
  },
  {
    id: 8,
    name: "Medical Social Worker",
  },
  {
    id: 9,
    name: "Nuclear Medicine Technologist",
  },
  {
    id: 10,
    name: "Nurse",
  },
  {
    id: 11,
    name: "Nutritionist",
  },
  {
    id: 12,
    name: "Occupational Therapist",
  },
  {
    id: 13,
    name: "Optometrist",
  },
  {
    id: 14,
    name: "Physical Therapist",
  },
  {
    id: 15,
    name: "Physician",
  },
  {
    id: 16,
    name: "Physician Assistant",
  },
  {
    id: 17,
    name: "Psychologist",
  },
  {
    id: 18,
    name: "Radiation Therapist",
  },
  {
    id: 19,
    name: "Respiratory Therapist",
  },
  {
    id: 20,
    name: "Speech-Language Pathologist",
  },
  {
    id: 21,
    name: "Pharmacist",
  },
  {
    id: 22,
    name: "Other",
  },
  {
    id: 23,
    name: "Nursing Home Administrator",
  },
  {
    id: 24,
    name: "Senior Living Executive Director",
  },
  {
    id: 26,
    name: "Open to all",
  },
  {
    id: 27,
    name: "Health Care Professional Student",
  },
  {
    id: 28,
    name: "Mental Health Therapist",
  },
  {
    id: 29,
    name: "EMT"
  },
  {
    id: 30,
    name: "Surgical Technologist"
  }
]

export const specialties = [
  {
    id: 0,
    name: "Allergy/Immunology",
  },
  {
    id: 1,
    name: "Anesthesiology",
  },
  {
    id: 2,
    name: "Audiology",
  },
  {
    id: 3,
    name: "Cardiology",
  },
  {
    id: 4,
    name: "Critical Care",
  },
  {
    id: 5,
    name: "Dermatology",
  },
  {
    id: 6,
    name: "Emergency Medicine",
  },
  {
    id: 7,
    name: "Endocrinology",
  },
  {
    id: 8,
    name: "ENT",
  },
  {
    id: 9,
    name: "Family Medicine",
  },
  {
    id: 10,
    name: "Gastroenterology",
  },
  {
    id: 11,
    name: "General Medicine",
  },
  {
    id: 12,
    name: "General Surgery",
  },
  {
    id: 13,
    name: "Geriatric Medicine",
  },
  {
    id: 14,
    name: "Glaucoma Specialist",
  },
  {
    id: 15,
    name: "Infectious Diseases",
  },
  {
    id: 16,
    name: "Internal Medicine",
  },
  {
    id: 17,
    name: "Neonatal Medicine",
  },
  {
    id: 18,
    name: "Nephrology",
  },
  {
    id: 19,
    name: "Neurology",
  },
  {
    id: 20,
    name: "Neurosurgery",
  },
  {
    id: 21,
    name: "Ob/Gyn",
  },
  {
    id: 22,
    name: "Oncology/Hematology",
  },
  {
    id: 23,
    name: "Ophthalmology",
  },
  {
    id: 24,
    name: "Oral Surgery",
  },
  {
    id: 25,
    name: "Oral/Maxillofacial Surgery",
  },
  {
    id: 26,
    name: "Orthodontics",
  },
  {
    id: 27,
    name: "Orthopedic Surgery",
  },
  {
    id: 28,
    name: "Pain Mgmt",
  },
  {
    id: 29,
    name: "Pathology",
  },
  {
    id: 30,
    name: "Pediatric Dentistry",
  },
  {
    id: 31,
    name: "Pediatrics",
  },
  {
    id: 32,
    name: "Pediatric Dentistry",
  },
  {
    id: 33,
    name: "Plastic Surgery",
  },
  {
    id: 34,
    name: "Podiatry",
  },
  {
    id: 35,
    name: "Preventive Medicine",
  },
  {
    id: 36,
    name: "Primary Care",
  },
  {
    id: 37,
    name: "Psychiatry",
  },
  {
    id: 38,
    name: "Psychology",
  },
  {
    id: 39,
    name: "Pulmonology",
  },
  {
    id: 40,
    name: "Radiology",
  },
  {
    id: 41,
    name: "Rheumatology",
  },
  {
    id: 42,
    name: "Thoracic Surgery",
  },
  {
    id: 43,
    name: "Urology",
  },
  {
    id: 44,
    name: "Vascular Surgery",
  },
  {
    id: 45,
    name: "Veterinary Medicine",
  },
  {
    id: 46,
    name: "Radiation Oncology",
  },
  {
    id: 47,
    name: "Physical Medicine & Rehabilitation",
  },
]

export const trainingLevels = [
  {
    id: 0,
    name: "Resident",
  },
  {
    id: 1,
    name: "Fellow",
  },
  {
    id: 2,
    name: "Attending",
  },
  {
    id: 3,
    name: "Other",
  },
]

export const heightOptions = {
  feet: [
    {
      name: "3'",
      id: 3,
    },
    {
      name: "4'",
      id: 4,
    },
    {
      name: "5'",
      id: 5,
    },
    {
      name: "6'",
      id: 6,
    },
    {
      name: "7'",
      id: 7,
    },
    {
      name: "8'",
      id: 8,
    },
  ],
  inches: [
    {
      name: '0"',
      id: 0,
    },
    {
      name: '1"',
      id: 1,
    },
    {
      name: '2"',
      id: 2,
    },
    {
      name: '3"',
      id: 3,
    },
    {
      name: '4"',
      id: 4,
    },
    {
      name: '5"',
      id: 5,
    },
    {
      name: '6"',
      id: 6,
    },
    {
      name: '7"',
      id: 7,
    },
    {
      name: '8"',
      id: 8,
    },
    {
      name: '9"',
      id: 9,
    },
    {
      name: '10"',
      id: 10,
    },
    {
      name: '11"',
      id: 11,
    },
  ],
}

export const minHeight = 36
export const maxHeight = 84

export const radiusOptions = [
  {
    id: 10,
    name: "Up to 10 Miles",
  },
  {
    id: 50,
    name: "Up to 50 Miles",
  },
  {
    id: 100,
    name: "Up to 100 Miles",
  },
  {
    id: 500,
    name: "> 100 Miles",
  },
]

export const radiusOptionsPreferences = [
  {
    id: 10,
    name: "Up to 10 Miles",
  },
  {
    id: 50,
    name: "Up to 50 Miles",
  },
  {
    id: 100,
    name: "Up to 100 Miles",
  },
  {
    id: 500,
    name: "> 100 Miles",
  },
]

export const ageOptionsPreferences = [
  {
    id: 1,
    name: "18-28",
  },
  {
    id: 2,
    name: "28-38",
  },
  {
    id: 3,
    name: "38-48",
  },
  {
    id: 4,
    name: "48-58",
  },
  {
    id: 5,
    name: "58+",
  },
]

export const heightOptionsPreferences = [
  {
    id: 1,
    name: "5' or less",
  },
  {
    id: 2,
    name: "5' - 5'6",
  },
  {
    id: 3,
    name: "5'6 - 6'",
  },
  {
    id: 4,
    name: "6' - 6'5",
  },
  {
    id: 5,
    name: "6'5+",
  },
]

export const familyOptionsPreferences = [
  {
    id: 0,
    name: "Don't have Children / Want Children",
  },
  {
    id: 1,
    name: "Has Children / Want More",
  },
  {
    id: 2,
    name: "Don't Want Children",
  },
  {
    id: 3,
    name: "Have Children / Don't Want More",
  },
  {
    id: 4,
    name: "Open to all",
  },
]

export const hobbyOptions = [
  {
    id: 0,
    name: "Outdoor Activities",
  },
  {
    id: 1,
    name: "Exercise",
  },
  {
    id: 2,
    name: "Yoga/Meditation",
  },
  {
    id: 3,
    name: "Reading",
  },
  {
    id: 4,
    name: "Writing",
  },
  {
    id: 5,
    name: "Watching TV",
  },
  {
    id: 6,
    name: "Watching Movies",
  },
  {
    id: 7,
    name: "Cooking",
  },
  {
    id: 8,
    name: "Traveling",
  },
  {
    id: 9,
    name: "Drawing/Painting",
  },
  {
    id: 10,
    name: "Gardening",
  },
  {
    id: 11,
    name: "Photography",
  },
]

export const ethnicityOptions = [
  {
    id: 0,
    name: "Black/African American",
  },
  {
    id: 1,
    name: "Caucasian",
  },
  {
    id: 2,
    name: "East Asian",
  },
  {
    id: 3,
    name: "Latin/Hispanic",
  },
  {
    id: 4,
    name: "South Asian",
  },
  {
    id: 5,
    name: "Middle Eastern",
  },
  {
    id: 7,
    name: "Other",
  },
  {
    id: 6,
    name: "Open to all",
  },
]

export const religionOptions = [
  {
    id: 0,
    name: "Agnostic",
  },
  {
    id: 1,
    name: "Atheist",
  },
  {
    id: 2,
    name: "Buddhist",
  },
  {
    id: 3,
    name: "Catholic",
  },
  {
    id: 4,
    name: "Christian",
  },
  {
    id: 5,
    name: "Hindu",
  },
  {
    id: 9,
    name: "Jain",
  },
  {
    id: 6,
    name: "Jewish",
  },
  {
    id: 10,
    name: "Mormon",
  },
  {
    id: 13,
    name: "Muslim",
  },
  {
    id: 12,
    name: "Sikh",
  },
  {
    id: 7,
    name: "Spiritual",
  },
  {
    id: 11,
    name: "Zoroastrian",
  },
  {
    id: 8,
    name: "Open to all",
  },
]

export const familyOptions = [
  {
    id: 0,
    name: "Don't have Children / Want Children",
  },
  {
    id: 1,
    name: "Has Children / Want More",
  },
  {
    id: 2,
    name: "Don't Want Children",
  },
  {
    id: 3,
    name: "Have Children / Don't Want More",
  },
  {
    id: 4,
    name: "Open to all",
  },
]

export const languageOptions = [
  {
    id: 0,
    name: "English",
  },
  {
    id: 1,
    name: "Chinese/Mandarin",
  },
  {
    id: 2,
    name: "Spanish",
  },
  {
    id: 3,
    name: "Arabic",
  },
  {
    id: 4,
    name: "Portuguese",
  },
]

export const politicalOptions = [
  {
    id: 0,
    name: "Democrat",
  },
  {
    id: 1,
    name: "Republican",
  },
  {
    id: 2,
    name: "Independent",
  },
  {
    id: 3,
    name: "Green",
  },
  {
    id: 4,
    name: "Libertarian",
  },
  {
    id: 5,
    name: "Open to all",
  },
]

export const unmatchOptions = [
  {
    id: 0,
    name: "No longer interested",
  },
  {
    id: 1,
    name: "Inappropriate",
  },
  {
    id: 2,
    name: "Unable to make distance work",
  },
]

export const reportOptions = [
  {
    id: 0,
    name: "Inappropriate Behavior",
  },
  {
    id: 1,
    name: "Inappropriate Profile Content",
  },
  {
    id: 2,
    name: "Fake Profile",
  },
]

export const contactOptions = [
  {
    id: 0,
    name: "Ask a General Question",
  },
  {
    id: 1,
    name: "Help with Payment",
  },
  {
    id: 2,
    name: "Report a Safety Concern",
  },
  {
    id: 3,
    name: "Report a Technical Issue",
  },
]

export const ignoreOptions = [
  {
    id: 0,
    name: "Not interested",
  },
  {
    id: 1,
    name: "Inappropriate",
  },
  {
    id: 2,
    name: "Unable to make distance work",
  },
]

export const preferenceOptions = [
  {
    id: "firstName",
    name: "Name",
    value: null,
  },
  {
    id: "birthday",
    name: "Date of Birth",
    value: null,
  },
  {
    id: "gender",
    name: "Gender",
    value: null,
  },
  {
    id: "height",
    name: "Height",
    value: null,
  },
  {
    id: "currentCity",
    name: "Location",
    value: null,
  },
  {
    id: "radius",
    name: "Search Radius",
    value: null,
  },

  {
    id: "vaccinated",
    name: "Vaccination",
    value: null,
  },
  {
    id: "profession",
    name: "Profession",
    value: null,
  },
  {
    id: "specialty",
    name: "Medical Specialty",
    value: null,
  },
  {
    id: "training",
    name: "Level of Training",
    value: null,
  },
  {
    id: "bio",
    name: "Bio",
    value: null,
  },
  {
    id: "ethnicity",
    name: "Ethnicity",
    value: null,
  },
  {
    id: "family",
    name: "Family Planning",
    value: null,
  },
  {
    id: "hobbies",
    name: "Hobbies",
    value: null,
  },
  // {
  //   id: 'homeTown',
  //   name: 'Hometown',
  //   value: null,
  // },
  // {
  //   id: 'languages',
  //   name: 'Languages',
  //   value: null,
  // },
  {
    id: "lazySunday",
    name: "Lazy Sunday",
    value: null,
  },
  {
    id: "politicalAffiliation",
    name: "Political Affiliation",
    value: null,
  },
  {
    id: "religion",
    name: "Religion",
    value: null,
  },
  {
    id: "whatAreYouLookingFor",
    name: "What Are You Looking For?",
    value: null,
  },
]

export const matchPreferenceOptions = [
  {
    id: "matchPreferenceGender",
    name: "Interested In",
    value: null,
  },
  {
    id: "radius",
    name: "Search Radius",
    value: null,
  },
  {
    id: "matchPreferenceAge",
    name: "Age Range",
    value: null,
  },
  {
    id: "matchPreferenceHeight",
    name: "Height",
    value: null,
  },
  {
    id: "matchPreferenceEthnicity",
    name: "Ethnicity",
    value: null,
  },
  {
    id: "matchPreferencePoliticalAffiliation",
    name: "Political Affiliation",
    value: null,
  },
  {
    id: "matchPreferenceFamily",
    name: "Family Planning",
    value: null,
  },
  {
    id: "matchPreferenceReligion",
    name: "Religion",
    value: null,
  },
  {
    id: "matchPreferenceProfession",
    name: "Professions",
    value: null,
  },
]

export const hearAboutUsOptions = [
  {
    id: 'google',
    name: 'Google',
    value: 'google',
  },
  {
    id: 'facebook',
    name: 'Facebook',
    value: 'facebook',
  },
  {
    id: 'instagram',
    name: 'Instagram',
    value: 'instagram',
  },
  {
    id: 'twitter',
    name: 'Twitter',
    value: 'twitter',
  },
  {
    id: 'tiktok',
    name: 'TikTok',
    value: 'tiktok',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    value: 'linkedin',
  },
  {
    id: 'wordOfMouth',
    name: 'Word of Mouth',
    value: 'wordOfMouth',
  },
  {
    id: 'other',
    name: 'Other',
    value: 'other',
  },
  
]

export default {
  privacyLink: "https://foreverxapp.com/privacy-policy/",
  termsLink: "https://foreverxapp.com/terms-of-use/",
  copyrightLink: "https://foreverxapp.com/copyright-policy/",
  communityGuidelines: "https://foreverxapp.com/community-guidelines/",
  safetyGuidelines: "https://foreverxapp.com/safety-guidelines/",
}
