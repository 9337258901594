import React, { createContext, useContext, useEffect, useState } from "react";
import { getReported } from "services/reported-service";
import { useUsers } from "./UserContext";

export const ReportedContext = createContext();

export const ReportedProvider = ({ children }) => {
  const { userData } = useUsers();
  const [reported, setReported] = useState([]);
  const [reportedUsers, setReportedUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  const [startFetchGetReported, setStartFetchGetReported] = useState(false);

  useEffect(() => {
    if (!startFetchGetReported) return;
    getReported(setReported, userData);
  }, [startFetchGetReported])

  useEffect(() => {
    if (filter==='unresolved') {
      setReportedUsers(reported.filter((report) => !report.resolved));
    } else {
      setReportedUsers(reported.filter((report) => report.resolved));
    }
  }, [filter, reported])

  return (
    <ReportedContext.Provider
      value={{
        filter, setFilter,
        reportedUsers, setReportedUsers,
        setStartFetchGetReported
      }}
    >
      {children}
    </ReportedContext.Provider>
  );
};

export const useReported = () => useContext(ReportedContext);
