import { auth, firestore } from "utils/firebase"

export const login = async (
  email,
  password,
  setEmailError,
  setPasswordError,
  setCurrentUser
) => {
  const querySnapshot = await firestore
    .collection("admins")
    .where("email", "==", email)
    .get()

  if (querySnapshot.docs.length > 0) {
    
    await auth.signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      setCurrentUser(querySnapshot.docs[0].data().adminId)
    })
    .catch((err) => {
      console.log("error signing in", err)
      //return errors

      if (err.code === "auth/invalid-email") {
        setEmailError("Invalid email address")
      } else {
        setPasswordError("Invalid password")
      }
    })
  } else {
    setEmailError("No ForeverX Admin corresponding to this email address")
  }
}

export const logout = () => {
  auth.signOut().then(() => {
    window.location.reload(false)
  })
}

export const sendPasswordReset = (email) => {
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      window.alert("Password reset email successfully sent")
    })
    .catch((e) => {
      window.alert(e.message)
      // console.log('error sending password reset', e)
    })
}
