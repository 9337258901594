import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUsers } from "context/UserContext";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { Header } from "components/Header";
import { PrimaryText } from "components/Common/PrimaryText";
import { getAge, getHobby, getUserInfo } from "utils/helpers";
import { ConfirmModal } from "components/Modals/Confirmation";
import {
  deleteUser,
  listenToSelectedUser,
  updateUser,
} from "services/user-service";
import { cloneDeep } from "lodash";
import { FiEdit } from "react-icons/fi";

export const UserDetail = () => {
  const { selectedUser, userData, images, usersWhoNeedVerification } =
    useUsers();
  const navigate = useNavigate();

  const [user, setUser] = useState(cloneDeep(selectedUser));
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [adminNotes, setAdminNotes] = useState(user?.adminNotes);

  useEffect(() => {
    function onUpdateUser(updatedUser) {
      setUser(updatedUser);
      let findUserData = userData.findIndex((x) => x.id === updatedUser.id);
      if (findUserData > -1) {
        userData[findUserData] = updatedUser;
      }

      let findVerificationUser = usersWhoNeedVerification.findIndex(
        (x) => x.id === updatedUser.id
      );
      if (findVerificationUser > -1) {
        usersWhoNeedVerification[findVerificationUser] = updatedUser;
      }
    }
    const sub = listenToSelectedUser(selectedUser, onUpdateUser, () => {});
    return () => {
      sub();
    };
  }, [selectedUser, userData, usersWhoNeedVerification]);

  function onDeleteUser() {
    const findIndex = userData.findIndex((x) => x.id === user.id);
    userData.splice(findIndex, 1);

    deleteUser(user, () => navigate(-1));
  }

  let userDetails = [
    {
      title: "Verified",
      value: user?.verified ? "True" : "False",
    },
    {
      title: "Age",
      value: getAge(selectedUser)?.value,
    },
    {
      title: "Profession",
      value:
        user?.profession === 22
          ? user?.otherProfession + "(Other)"
          : getUserInfo("profession", user?.profession),
    },
    user?.profession === 27 && {
      title: "Other Student Type",
      value: user?.otherProfession
    },
    {
      title: "Specialty",
      value: getUserInfo("specialty", user?.specialty),
    },
    {
      title: "Training",
      value: getUserInfo("training", user?.training),
    },
    {
      title: "Current City",
      value: getUserInfo("currentCity", user?.currentCity) + ", " + user?.state,
    },
    {
      title: "Height",
      value: getUserInfo("height", user?.height),
    },
    {
      title: "Match Preference",
      value: getUserInfo("matchPreferenceGender", user?.matchPreferenceGender),
    },
  ];

  const userDetails2 = [
    {
      title: "About",
      value: getUserInfo("bio", user?.bio),
    },
    {
      title: "Hobbies",
      value: getHobby(user?.hobbies, false),
    },
    {
      title: "Family",
      value: getUserInfo("family", user?.family),
    },
    {
      title: "Ethnicity",
      value: getUserInfo("ethnicity", user?.ethnicity),
    },
    {
      title: "Religion",
      value: getUserInfo("religion", user?.religion),
    },
    {
      title: "Political Affiliation",
      value: getUserInfo("politicalAffiliation", user?.politicalAffiliation),
    },
  ];

  let detail2Test = userDetails2.filter((x) => x.value && x.value?.length > 0);

  return (
    <View style={styles.container}>
      <Header
        title={user?.firstName + " " + user?.lastName}
        onBack={() => navigate(-1)}
        onRight={() => setShowConfirmModal(true)}
        rightText={"Delete"}
      />

      {/** ROW 1 */}
      <View style={styles.info}>
        <View style={{ width: "25%" }}>
          <View style={styles.defaultPic}>
            <Image
              style={{ width: "100%", height: "100%", borderRadius: 12 }}
              source={{ uri: user?.defaultPic }}
              resizeMode={"contain"}
            />
          </View>
        </View>

        <View style={styles.infoContainer}>
          {userDetails.map((u, i) => {
            const { title, value } = u;
            if (!value) return null;
            return (
              <View style={styles.infoTextRow} key={i}>
                <View style={{ marginRight: 12 }} className="text-right mr-4">
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ width: 180, textAlign: "right" }}
                  >
                    {title}
                  </PrimaryText>
                </View>
                <PrimaryText
                  fontSize={16}
                  fontWeight={600}
                  style={u?.capitalize && { textTransform: "capitalize" }}
                >
                  {value}
                </PrimaryText>
              </View>
            );
          })}
        </View>

        <View style={[styles.infoContainer, { marginLeft: 12 }]}>
          <View style={{ flex: 1 }}>
            <View style={{ marginRight: 12 }} className="text-right mr-4">
              <PrimaryText fontSize={16} fontWeight={600}>
                {"Update Verification Status"}
              </PrimaryText>
            </View>
            <View style={styles.verificationStatusContainer}>
              <TouchableOpacity
                onPress={() => updateUser(selectedUser, { verified: true })}
                style={[
                  styles.verified,
                  {
                    borderColor: user?.verified
                      ? COLORS.primary.lightBlue
                      : COLORS.primary.gray,
                    height: 40,
                  },
                ]}
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={400}
                  style={{
                    color: user?.verified
                      ? COLORS.primary.lightBlue
                      : COLORS.primary.gray,
                  }}
                >
                  Verified
                </PrimaryText>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => updateUser(selectedUser, { verified: false })}
                style={[
                  styles.verified,
                  {
                    borderColor: !user?.verified
                      ? COLORS.primary.lightBlue
                      : COLORS.primary.gray,
                    height: 40,
                  },
                ]}
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={400}
                  style={{
                    color: !user?.verified
                      ? COLORS.primary.lightBlue
                      : COLORS.primary.gray,
                  }}
                >
                  Unverified
                </PrimaryText>
              </TouchableOpacity>
            </View>
            {!!user?.manualVerification && (
              <>
                <PrimaryText
                  fontSize={16}
                  fontWeight={600}
                  style={{ marginBottom: 12 }}
                >
                  {"Manual Verification Info"}
                </PrimaryText>
                <View style={styles.infoTextRow}>
                  <View style={{ marginRight: 12 }}>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      // style={{ width: 180, textAlign: "right" }}
                    >
                      {"ID Number"}
                    </PrimaryText>
                  </View>
                  <PrimaryText fontSize={16} fontWeight={600}>
                    {user?.manualVerification?.manualID}
                  </PrimaryText>
                </View>
                <View style={styles.infoTextRow}>
                  <View style={{ marginRight: 12 }}>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      // style={{ width: 180, textAlign: "right" }}
                    >
                      {"ID Type"}
                    </PrimaryText>
                  </View>
                  <PrimaryText fontSize={16} fontWeight={600}>
                    {user?.manualVerification?.manualType}
                  </PrimaryText>
                </View>
              </>
            )}

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <View style={{ marginRight: 12 }}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={400}
                  style={{ textAlign: "left" }}
                >
                  {"Email"}
                </PrimaryText>
              </View>
              <PrimaryText fontSize={16} fontWeight={600}>
                {user?.email}
              </PrimaryText>
            </View>
          </View>
        </View>
      </View>

      {/** ROW 2 */}

      <View style={styles.info}>
        {detail2Test?.length > 0 && (
          <View style={styles.infoContainer}>
            {userDetails2.map((u, i) => {
              const { title, value } = u;
              if (!value || value?.length === 0) return null;
              return (
                <View style={styles.infoTextRow} key={i}>
                  <View style={{ marginRight: 12 }} className="text-right mr-4">
                    <PrimaryText
                      fontSize={14}
                      fontWeight={400}
                      style={{ width: 180, textAlign: "right" }}
                    >
                      {title}
                    </PrimaryText>
                  </View>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={600}
                    style={u?.capitalize && { textTransform: "capitalize" }}
                  >
                    {value}
                  </PrimaryText>
                </View>
              );
            })}
          </View>
        )}
        <View style={[styles.infoContainer, { marginLeft: 12 }]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PrimaryText fontSize={16} fontWeight={600}>
              {"Admin Notes"}
            </PrimaryText>
            <TouchableOpacity
              onPress={() => {
                setEditing(true);
              }}
              style={{
                backgroundColor: COLORS.primary.gray,
                padding: 8,
                borderRadius: 5,
              }}
            >
              <FiEdit color={COLORS.primary.white}/>
            </TouchableOpacity>
          </View>
          {editing ? (
            <View style={{ flex: 1 }}>
              <TextInput
                style={{
                  height: 100,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 8,
                  marginTop: 12,
                }}
                onChangeText={(text) => setAdminNotes(text)}
                value={adminNotes}
                multiline={true}
                numberOfLines={4}
              />
              <View style={{ flexDirection: "row", marginTop: 12 }}>
                <TouchableOpacity
                  onPress={() => {
                    setEditing(false);
                    setAdminNotes(user?.adminNotes);
                  }}
                  style={{
                    backgroundColor: COLORS.primary.gray,
                    padding: 8,
                    borderRadius: 5,
                    marginRight: 12,
                  }}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ color: COLORS.primary.white }}
                  >
                    {"Cancel"}
                  </PrimaryText>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setEditing(false);
                    updateUser(selectedUser, { adminNotes });
                  }}
                  style={{
                    backgroundColor: COLORS.primary.lightBlue,
                    padding: 8,
                    borderRadius: 5,
                  }}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ color: COLORS.primary.white }}
                  >
                    {"Save"}
                  </PrimaryText>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={{ marginTop: 12 }}
              >
                {user?.adminNotes}
              </PrimaryText>
            </View>
          )}
        </View>
      </View>

      {/** ROW 3 */}
      <View style={styles.info}>
        <View style={[styles.infoContainer, { maxWidth: undefined }]}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {images &&
              images.map((u, i) => {
                return (
                  <View
                    style={{ height: 500, width: "50%", padding: 24 }}
                    key={i}
                  >
                    <Image
                      source={u}
                      style={{ width: "100%", height: "100%" }}
                      aspectRatio={16 / 9}
                      resizeMode={"contain"}
                    />
                  </View>
                );
              })}
          </View>
        </View>
      </View>
      {showConfirmModal && (
        <ConfirmModal
          title={"Delete User"}
          message={"Are you sure?"}
          onConfirm={onDeleteUser}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    flex: 1,
    width: "100%",
    paddingVertical: 20,
    backgroundColor: COLORS.primary.white,
    paddingHorizontal: 12,
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  verificationStatusContainer: {
    // flex: 1,
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 12,
  },
  verified: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    marginBottom: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLORS.primary.lightBlue,
  },
  defaultPic: {
    // width: "100%",
    aspectRatio: 1,
    marginRight: 12,
  },
  info: {
    flexDirection: "row",
    marginBottom: 16,
    // maxHeight: 300,
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
  },
});
