import React from "react";

const FormInput = (props) => {
  const {
    placeholder,
    value,
    setValue,
    type,
    formInfo,
    formMultiInfo,
    label,
    bgWhite,
    h25,
    min,
    disable,
  } = props;

  return (
    <div>
      {label ? <label className="f-18 fw-300 mb-12">{label}</label> : null}
      <input
        disabled={disable}
        min={type === "number" && min}
        type={type}
        placeholder={placeholder}
        className={`app-input ${type == "search" ? "search" : null} ${
          bgWhite ? "bg-white" : null
        } ${h25 ? "height-25 f-12" : null}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {formInfo ? (
        <small className="form-text fw-400 f-14 text-left">{formInfo}</small>
      ) : null}
      {formMultiInfo ? (
        <div>
          <div className="flex-between-center mb-1">
            <small className="form-text fw-400 f-12 text-gray">
              * Minimum 8 characters
            </small>
            <small className="form-text fw-400 f-12 text-gray">
              * At least one number
            </small>
          </div>
          <div className="flex-between-center">
            <small className="form-text fw-400 f-12 text-gray">
              * Lowercase & uppercase letters
            </small>
            <small className="form-text fw-400 f-12 text-gray">
              * At least one symbol
            </small>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FormInput;
