import React, { useEffect } from "react"
import { View } from "react-native"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { COLORS } from "constants/Colors"

const SidebarLink = styled(Link)`
  display: flex;
  color: ${({ active }) =>
    active ? COLORS.primary.darkBlue : COLORS.primary.darkBlue};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  background: ${({ active }) =>
    active ? COLORS.primary.offWhite : "transparent"};
  border-right: ${({ active, showSidebar }) => (active ? showSidebar ? `12px solid ${COLORS.primary.darkBlue}` : `4px solid ${COLORS.primary.darkBlue}` : "")};
  &:hover {
    background: ${COLORS.primary.darkBlue};
    color: ${COLORS.primary.offWhite};
    border-left: 4px solid #1da1f2;
    cursor: pointer;
  }
`

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 14;
  font-weight: 400
`

const DropdownLink = styled(Link)`
  height: 35px;
  padding-left: 2.3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  background: ${({ active }) => (active ? "#1DA1F2" : "#414757")};

  &:hover {
    background: #1da1f2;
    cursor: pointer;
  }
`

export const SubMenu = ({
  item,
  currentUser,
  showSidebar,
  setShowSidebar,
  subnav,
  setSubnav,
}) => {
  const location = useLocation()

  const showSubnav = () => setSubnav(subnav === item.title ? null : item.title)

  useEffect(() => {
    !!subnav && setShowSidebar(true)
  }, [subnav])

  useEffect(() => {
    !showSidebar && setSubnav(null)
  }, [showSidebar])

  return (
    <>
      <SidebarLink
        to={item.path ? item.path : "#"}
        onClick={item.subNav && showSubnav}
        active={
          item?.path === location.pathname ||
          (item.detailedNav &&
            item.detailedNav.find((x) => x.path === location.pathname))
            ? 1
            : 0
        }
        showSidebar={showSidebar}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {item.icon}
          {showSidebar && <SidebarLabel>{item.title}</SidebarLabel>}
        </View>
        <View>
          {item.subNav && subnav === item.title
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </View>
      </SidebarLink>
      {subnav === item.title &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink
              to={item.path}
              key={index}
              active={item?.path === location.pathname ? 1 : 0}
            >
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}
