import React from "react";
import { useAuth } from "context/AuthContext";
import { Landing } from "screens/Auth/Landing";
import Sidebar from "components/Sidebar/Sidebar";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import MainNavigator from "./MainNavigator";
import { Links } from "constants/Links";
import { Dimensions, View } from "react-native";
import { UsersProvider } from "context/UserContext";
import { ForeverXProvider } from "context/ForeverXContext";
import { ReportedProvider } from "context/ReportedContext";
import { FeedbackProvider } from "context/FeedbackContext";
import { MessagesProvider } from "context/MessagesContext";
import { HeardAboutUsProvider } from "context/HeardAboutUsContext";

export default function RootNavigator() {
  const authContext = useAuth();
  const { currentUser } = authContext;

  const { height } = Dimensions.get("window");

  return currentUser === null ? (
    <Router>
      <View
        style={{
          flex: 1,
          height: height,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 24,
          minWidth: 1024,
          paddingTop: 12,
        }}
      >
        <Routes>
          <Route path={Links.Landing} element={<Landing />} />
          <Route
            path={"/*"}
            element={<Navigate replace to={Links.Landing} />}
          />
        </Routes>
      </View>
    </Router>
  ) : (
    <ForeverXProvider>
      <UsersProvider>
        <ReportedProvider>
          <FeedbackProvider>
            <HeardAboutUsProvider>
            <MessagesProvider>
            <Router>
              <Sidebar />
              <MainNavigator />
            </Router>
            </MessagesProvider>
            </HeardAboutUsProvider>
          </FeedbackProvider>
        </ReportedProvider>
      </UsersProvider>
    </ForeverXProvider>
  );
}
