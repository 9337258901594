import { firestore } from "utils/firebase";

export const getReported = async (setReported, userData) => {
  console.log('getReported', userData?.length)
  const querySnapshot = await firestore.collection("reportedUsers").get()
  .catch((error) => {
    console.error("Error getting documents: ", error);
  });

  const reported = [];

  querySnapshot.forEach((doc) => {
    const report = doc.data();
    const reportedUser = userData.find((x) => report.reportedUser === x.id);
    const user = userData.find((x) => report.userId === x.id);
    if (!reportedUser || !user) return
    reported.push({ ...doc.data(), id: doc.id, reportedUser, user });
  });

  const reports = reported.filter((x) => x !== null);
  console.log('setReported', reports?.length)
  setReported(reports);
  return
}

export const resolveReport = async (report, resolved) => {
  await firestore.collection("reportedUsers").doc(report.id).update({ resolved });
}
