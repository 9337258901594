import React, { createContext, useContext, useEffect, useState } from "react";
import { getFeedback } from "services/feedback-service";
import { useUsers } from "./UserContext";

export const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const { userData } = useUsers();
  const [feedback, setFeedback] = useState([]);
  const [feedbackUsers, setFeedbackUsers] = useState([]);
  const [filter, setFilter] = useState("unresolved");

  const [startFetchGetFeedback, setStartFetchGetFeedback] = useState(false);

  useEffect(() => {
    if (!startFetchGetFeedback) return;
    getFeedback(setFeedback, userData);
  }, [startFetchGetFeedback])

  useEffect(() => {
    if (filter==='unresolved') {
      setFeedbackUsers(feedback.filter((report) => !report.resolved));
    } else {
      setFeedbackUsers(feedback.filter((report) => report.resolved));
    }
  }, [filter, feedback])

  return (
    <FeedbackContext.Provider
      value={{
        filter, setFilter,
        feedbackUsers, setFeedbackUsers,
        setStartFetchGetFeedback
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => useContext(FeedbackContext);
