import { TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Table from "components/Common/TableComponent/Table/Table";
import loginLeft from "assets/images/loginLeft.jpg";
import { useNavigate } from "react-router-dom";
import { useUsers } from "context/UserContext";
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { Links } from "constants/Links";
import { CustomPicker } from "components/Inputs/CustomPicker";
import moment from "moment";
import { FullModal } from "components/Modals/FullModal";
import { useMessages } from "context/MessagesContext";
import { archiveAdminChat } from "services/messages-service";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.css";
import styles2 from "./styles.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
} from "@chatscope/chat-ui-kit-react";

const { height, width } = Dimensions.get("window");
const pageSize = 10;


const options = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];
export const MessagingList = () => {
  const navigate = useNavigate();

  const {
    filteredChats,
    filter,
    setFilter,
    adminMessages,
    selectedAdminChat,
    setSelectedAdminChat,
    sendText,
    setStartFetchGetAdminMessages
  } = useMessages();
  const { nextPage, loading, setSelectedUser, selectedView } = useUsers();

  // Table related part
  const [page, setPage] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setStartFetchGetAdminMessages(true);
  }, []);

  function userSelected(user) {
    setSelectedUser(user);
    navigate(Links.UserDetail);
  }

  function archiveItem(value) {
    selectedItem.active = value;
    archiveAdminChat(selectedItem, value);
    const findIndex = filteredChats.findIndex((x) => x.id === selectedItem.id);
    filteredChats[findIndex] = selectedItem;
    setSelectedItem(null);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return filteredChats.sort((a, b) =>
      a?.lastMessage.lastUpdated.toDate() < b?.lastMessage.lastUpdated.toDate()
        ? 1
        : -1
    );
  };

  function onSend(text) {
    console.log(text);
    sendText(text);
  }

  const totalCount = filteredChats?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  if (!currentTableData() || currentTableData()?.length === 0) {
    return (
      <View
        style={{
          height: height - 100,
          width: "100%",
          flexDirection: "column",
          backgroundColor: COLORS.primary.white,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator
          animating={true}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  return (
    <View style={ourStyles.tableContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 24,
          marginTop: 12,
        }}
      >
        {/* <FaIcons.FaSearch size={16} color={COLORS.primary.darkGray} /> */}
        <div className="flex-1" style={{ marginRight: 12 }}>
          {/* <FormInput
            type="search"
            placeholder="Search user by name"
            value={search}
            setValue={setSearch}
          /> */}
        </div>
        <CustomPicker
          style={{ maxWidth: 175 }}
          mandatory
          value={filter}
          onChange={(value) => setFilter(value)}
          options={options}
        />
      </View>
      {currentTableData() && (
        <View style={{ flexDirection: "row", height: height - 200 }}>
          <View style={{ width: (width - 200) / 5 }}>
            <Table>
              {/* <TableHead>
            <TableRow>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}></PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={20} fontWeight={800}>
                  User
                </PrimaryText>
              </TableCell>

              <TableCell style={{ textAlign: "center" }} />
            </TableRow>
          </TableHead> */}
              <TableBody>
                {currentTableData().map((item, index) => {
                  return (
                    <TableRow
                      hover
                      key={item.id}
                      onClick={() => setSelectedAdminChat(item)}
                      selected={selectedAdminChat?.id === item?.id}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <TableCell
                        style={{ minHeight: 50, padding: 0, margin: 0 }}
                        onClick={() => userSelected(item.user)}
                      >
                        <img
                          src={item.user.defaultPic ? item.user.defaultPic : loginLeft}
                          style={{
                            height: 50,
                            padding: 0,
                            margin: 0,
                            resizeMode: "cover",
                            // objectFit: "cover",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <View style={{ width: (width - 250) / 7 }}>
                          <PrimaryText fontSize={14} fontWeight={400}>
                            {item?.user?.firstName} {item?.user?.lastName}
                          </PrimaryText>
                          <PrimaryText
                            fontSize={14}
                            fontWeight={400}
                            numberOfLines={1}
                            style={{ width: "100%" }}
                          >
                            {item?.lastMessage?.text}
                          </PrimaryText>
                          {/* <PrimaryText fontSize={14} fontWeight={400}>
                        {moment(item?.createdAt.toDate()).format(
                          "MMM DD, YYYY"
                        )}
                      </PrimaryText> */}
                        </View>
                      </TableCell>
                      {/* 
                  <TableCell style={{ textAlign: "center" }}>
                    <TouchableOpacity onPress={() => setSelectedItem(item)}>
                      <FiArchive size={20} color={COLORS.primary.darkBlue} />
                    </TouchableOpacity>
                  </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </View>

          <View style={{ flex: 3, height: height - 200 }}>
            {adminMessages && adminMessages?.length > 0 && (
              <div style={{ position: "relative", height: "100%" }}>
                <MainContainer style={{ borderWidth: 0, borderLeftWidth: 1 }}>
                  <ChatContainer>
                    <MessageList>
                      {adminMessages?.map((item, index) => {
                        return (
                          <Message
                            key={index}
                            model={{
                              message: item.text,
                              direction:
                                item.user._id === "admin"
                                  ? "outgoing"
                                  : "incoming",
                            }}

                          >
                            <Message.Footer
                              style={{ display: 'flex' }}
                              sender={item.user.name}
                              sentTime={moment(item.createdAt).format(
                                "MMM DD, hh:ss A"
                              )}
                            />
                            {item.user._id !== "admin" && (
                              <Avatar src={selectedAdminChat?.user?.defaultPic} />
                            )}

                          </Message>
                        );
                      })}
                    </MessageList>
                    <MessageInput
                      placeholder="Type message here"
                      attachButton={false}
                      onSend={onSend}
                    />
                  </ChatContainer>
                </MainContainer>
              </div>
            )}
          </View>
        </View>
      )}
      {/* <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity
          onPress={() =>
            selectedView === "all" && page > 1 ? setPage(page - 1) : null
          }
        >
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <View style={{ width: 125, alignItems: "center" }}>
          <PrimaryText fontSize={14} fontWeight={400}>
            {`${startCount} - ${endCount} of ${totalCount}`}
          </PrimaryText>
        </View>

        <TouchableOpacity
          onPress={() =>
            page < totalPageCount ? nextPage(page, setPage) : null
          }
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View> */}
      {!!selectedItem && (
        <FullModal
          setShowModal={() => setSelectedItem(null)}
          title={!!selectedItem?.active ? "Archive?" : "Unarchive?"}
        >
          <View style={ourStyles.modalContainer}>
            <TouchableOpacity
              style={ourStyles.button}
              onPress={() => archiveItem(!selectedItem?.active)}
            >
              <PrimaryText
                fontSize={16}
                fontWeight={400}
                style={{ color: COLORS.primary.white }}
              >
                Yes
              </PrimaryText>
            </TouchableOpacity>
          </View>
        </FullModal>
      )}
    </View>
  );
};

const ourStyles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.darkBlue,
    // width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    paddingHorizontal: 24,
    margin: 12,
    borderRadius: 6,
  },
  modalContainer: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
});
