import React from "react"
import RootNavigator from "./navigators/RootNavigator"
import { AuthProvider } from "context/AuthContext"
import { DisplayProvider } from "context/DisplayContext"
import "./App.css"

function App() {
  return <RootNavigator />
}

export default () => {
  return (
    <DisplayProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </DisplayProvider>
  )
}
