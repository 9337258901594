import { TableHead } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Table from "components/Common/TableComponent/Table/Table"
import { firestore } from "utils/firebase"
import { useNavigate } from "react-router-dom"
import { StyleSheet, TouchableOpacity, View } from "react-native-web"
import { PrimaryText } from "components/Common/PrimaryText"
import { COLORS } from "constants/Colors"
import * as FaIcons from "react-icons/fa"
import { Links } from "constants/Links"

const headCells = [
  { id: "", cellClassName: "imageHeader", label: "", hideLabel: true },
  { id: "name", cellClassName: "", label: "Name", hideLabel: false },
  { id: "email", cellClassName: "", label: "Email", hideLabel: true },
  { id: "role", cellClassName: "", label: "Role", hideLabel: true },
  // { id: "location", cellClassName: "", label: "Current City", hideLabel: true, },
]

export const AdminSection = () => {
  const navigate = useNavigate()

  // Table related part
  const [rows, setRows] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")

  useEffect(() => {
    getAdmins()
  }, [])

  const getAdmins = () => {
    firestore.collection("admins").onSnapshot((snapshot) => {
      let users = []
      snapshot.forEach((doc) => {
        const id = doc.id
        const data = doc.data()
        const user = {
          id,
          ...data,
        }
        users.push(user)
      })

      setRows(users)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(rowsPerPage)
    setPage(0)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <View style={styles.tableContainer}>
      {rows && rows?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Name
                </PrimaryText>
              </TableCell>
              <TableCell>
                <PrimaryText fontSize={24} fontWeight={800}>
                  Email
                </PrimaryText>
              </TableCell>
              <TableCell>
                <TouchableOpacity
                  style={styles.button}
                  // onPress={() => setShowNewEventModal(true)}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={300}
                    style={{ color: COLORS.primary.white, marginRight: 12 }}
                  >
                    New Admin
                  </PrimaryText>
                  <FaIcons.FaPlus size={16} color={COLORS.primary.white} />
                </TouchableOpacity>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover key={row.email}>
                  <TableCell>
                    {row.firstName} {row.lastName}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  {/* <TableCell>
                      {roles.find((x) => x.id === row.role).name}
                    </TableCell> */}
                  <TableCell
                    className="moreOptionsCell cursorPointer"
                    onClick={() =>
                      navigate(Links.Dashboard)
                    }
                  >
                    Edit
                  </TableCell>
                  {/* <TableCell>{row.time?.toDate()?.toDateString()?.toString()}</TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
})
