import { firestore, storage } from "utils/firebase";

export const getFeedback = async (setFeedback, userData) => {
  console.log('getFeedback', userData?.length)
  const querySnapshot = await firestore
  .collection("ratings")
  .orderBy('createdAt', 'desc')
  .get();

  const reported = [];

  querySnapshot.forEach((doc) => {
    const user = userData.find((x) => doc.data().userId === x.id);
    if (!user) return
    reported.push({ ...doc.data(), id: doc.id, user });
  });

  const reports = reported.filter((x) => x !== null);
  console.log('setFeedback', reports?.length)
  // console.log('testing', reports)
  setFeedback(reports);
};