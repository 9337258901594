import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import * as FiIcons from "react-icons/fi";

import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";

export function ModalHeader({ title, onClose }) {
  return (
    <View style={styles.modalHeader}>
      <TouchableOpacity onPress={onClose} style={{ alignSelf: "flex-end" }}>
        <FiIcons.FiX
          color={COLORS.primary.gray}
          size={24}
          style={{ width: 40 }}
        />
      </TouchableOpacity>
      <PrimaryText
        fontSize={24}
        fontWeight={800}
        style={{ alignSelf: "center" }}
      >
        {title}
      </PrimaryText>
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    backgroundColor: COLORS.primary.white,
  },
});
