export const Links = {
  Landing: "/",

  Dashboard: "/dashboard",
  Team: "/team",
  Users: "/users",
  UserDetail: "/userDetail",

  ReportedUsers: "/reportedUsers",
  Feedback: "/feedback",
  HearAboutUs: "/hearAboutUs",
  Messaging: "/messaging",
};
