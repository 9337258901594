import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyCWDD2AzRrvpNEpdLX3-IaNaXm2ckfWrvw",
  authDomain: "foreverx-98fbd.firebaseapp.com",
  projectId: "foreverx-98fbd",
  storageBucket: "foreverx-98fbd.appspot.com",
  messagingSenderId: "932531902722",
  appId: "1:932531902722:web:e79ec77e4603c64174ca1b",
}

const app = firebase.initializeApp(firebaseConfig)

export const firestore = app.firestore()
export const time = firebase.firestore.FieldValue.serverTimestamp()
export const auth = app.auth()
export const storage = app.storage()
export const functions = app.functions()

// export { firestore, auth, storage, time }
export default app
