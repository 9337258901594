

export const COLORS = {
    primary: {
        lightBlue: '#1DA1F2',
        blue: 'rgba(92, 137, 211, 1)',
        darkBlue: '#161628',
        white: '#FFF',
        black: '#000',
        error: '#EE1D52',
        gray: '#999999',
        lightGray: '#F0F0F5',
        darkGray: '#444444',
        sunflower: '#FFE000',
        orange: '#FEAB09',
        offWhite: '#F6F6F6',
    },
    secondary: {
        offWhite: '#F6F6F6',
        opaqueWhite: 'rgba(255,255,255,0.95)',
        gray: '#828282',
        lightGray: '#E5E5EB'
    },
    background: {
        darkBlue: '#001935',
        lightBlue: '#1DA1F2',
        darkOrange: '#FEAB09',
        white: '#ffffff',
        offWhite: 'rgba(255,255,255,0.95)',
        lightRed: '#FEEFEF',
      },
}